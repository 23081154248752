import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  IonAvatar,
  IonItem,
  IonLabel,
  IonSkeletonText,
  IonThumbnail,
} from '@ionic/angular/standalone';

@Component({
  selector: 'cs-social-post-skeleton',
  standalone: true,
  imports: [
    CommonModule,
    IonItem,
    IonSkeletonText,
    IonLabel,
    IonAvatar,
    IonThumbnail,
  ],
  template: `
    <div>
      <ion-item lines="none">
        <ion-avatar class="w-14 h-14" slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-label>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>

          <ion-thumbnail class="mt-2">
            <ion-skeleton-text [animated]="true"></ion-skeleton-text>
          </ion-thumbnail>
        </ion-label>
      </ion-item>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      ion-thumbnail {
        width: 100%;
        height: 250px;
      }
    `,
  ],
})
export class SocialPostSkeleton implements OnInit {
  constructor() {}

  ngOnInit() {}
}
